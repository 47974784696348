<template>
  <div class="page">
    <loader :visible="loading" />
    <main-menu :title="$t('sign_in')"></main-menu>
    <div class="form">
      <form action="#" @submit="login">
        <div class="input">
          <label for="username">{{ $t("username") }}</label>
          <input id="username" v-model="username" type="text" />
        </div>
        <div class="input">
          <label for="password">{{ $t("password") }}</label>
          <input id="password" v-model="password" type="password" />
        </div>
        <custom-button
          :button="{
            text: $t('continue'),
            clickCallback: login,
          }"
        ></custom-button>
        <input
          type="submit"
          style="position: absolute; left: -99999px"
          tabindex="-1"
        />
      </form>
    </div>
  </div>
</template>

<script>
import MainMenu from "../components/MainMenu.vue";
import Loader from "../components/Loader.vue";
import CustomButton from "../components/CustomButton.vue";
import api from "../api";

export default {
  name: "Login",
  components: { MainMenu, Loader, CustomButton },
  metaInfo() {
    return {
      title: this.$i18n.t("sign_in"),
    };
  },
  data() {
    return {
      loading: false,
      username: null,
      password: null,
    };
  },
  computed: {},
  methods: {
    async login(e) {
      this.loading = true;
      e.preventDefault();
      try {
        const response = await api().post(`/user/login`, {
          username: this.username,
          password: this.password,
        });
        this.$root.jwtToken = response.data.jwt;
        this.$router.push({ name: "home" });
      } catch (error) {
        alert(error.response.data.message);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="less">
@import "../styles/form.less";
</style>
